.MainMenu-container {
  background-image: url(http://drive.google.com/uc?export=view&id=1ZOhDjU_UvrFWtlLCxpjyrYE2yc9ll8bi);
  display: flex;
  flex-direction: row;
  width: auto;
  height: 80px;
  background-color: transparent;
  justify-content: space-between;
  outline: 2px black;
  background-color: lightgray;
}

.MainMenu-itemcont {
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
}

.MainMenu-btncont {
  align-items: center;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  float: center;
  border: none;
  color: white;
  width: 10%;
}
