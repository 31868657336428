.btn-login {
  width: 100%;
  max-width: 20em;
  padding: 0.25em 0 0.4em 0;
  margin: 0.5em 0;
  text-align: center;
  color: white;
  border: none;
  border-radius: 4px;
  background-color: #1498a4;
}
