.menupeq-title {
  background-color: darkgray;
  width: 100%;
  max-width: 450px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  outline: 20px black;
}

.menu-link-decoration {
  text-decoration: none;
  color: white;
  background: #262626;
}

.producto-img {
  height: 80px;
  width: 80px;
}
