.exitolist-header {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
}

.exitolista-container {
  width: auto;
  height: 80px;
  outline: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exitolist-btn {
  text-decoration: none;
  height: auto;
  width: auto;
}
