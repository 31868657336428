.home-menu-item {
  width: 100%;
  max-width: 450px;
  margin-bottom: 0.4em;
}

.home-menu-item a {
  width: 100%;
  display: flex;
  text-decoration: none;
}

.home-menu-item a:hover span {
  color: #000000;
}

.home-menu-item img {
  height: 80px;
  width: 80px;
  padding: 10px;
  object-fit: contain;
  background: #262626;
}

.home-menu-item span {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  padding: 0 10%;
  background: #f4f4f4;
  color: #262626;
  font-size: 1.6em;
  text-transform: uppercase;
}
