.pagina-title,
.pagina-content {
  width: 100%;
  max-width: 800px;
  padding: 1em 2em;
}

.pagina-title {
  display: flex;
  align-items: center;
  background-color: #262626;
}

.pagina-title img {
  height: 6em;

}

.pagina-content {
  background: #f4f4f4;
  color: #262626;
}

.pagina-galeria {
  margin-top: 1em;
  background-color: transparent;
}

.pagina-galeria img {
  height: 6em;
  margin: 0 0.4em 0.4em 0;
  object-fit: contain;
  background:transparent;
}

.btns-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3em;
}

.btns-container button {
  min-width: 8em;
  min-height: 1.5em;
  padding: 0.25em 0.8em 0.4em 0.8em;
  margin-left: 0.8em;
  text-align: center;
  border: none;
  border-radius: 4px;
  background-color: #1498a4;
}

.btns-container button a {
  color: white;
  text-decoration: none;
}
