* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url("http://drive.google.com/uc?export=view&id=1ZOhDjU_UvrFWtlLCxpjyrYE2yc9ll8bi") no-repeat fixed center center;
  background-size: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-title {
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-title a {
  text-align: center;
  max-width: 85%;
}

.page-title img {
  height: 5em;
  max-width: 85%;
  object-fit: contain;
}

.page-title a img {
  max-width: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 1.5em 1em;
  overflow-y: auto;
}
.page-container.center {
  justify-content: center;
}
