.etapas-menu-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.etapas-menu-item {
  width: calc(33.3% - 0.8em);
  padding: 2em;
  margin: 0 0.4em;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.etapas-menu-item img {
  width: 80px;
  max-width: 100%;
  object-fit: contain;
  filter: invert(1);
}

.etapas-menu-item p {
  padding-top: 0.4em;
  color: #ffffff;
  font-size: 1.4em;
  margin-bottom: 0;
  color: #262626;
}

.etapas-text-container {
  width: calc(100% - 0.8em);
  max-width: calc(800px - 0.8em);
  margin: 0 0.4em;
  padding: 1.5em 10%;
  background-color: #f4f4f4;
  color: #262626;
  line-height: 1.6em;
  text-align: center;
  font-size: 1.1em;
}
