.Agendar-pregunta-container {
  width: 100%;
  height: auto;
  display: flex;
  color: white;
}

.pregunta-agendar {
  max-width: 450px;
  height: auto;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.date-menu-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
}

.pregunta-agendar input {
  width: 100%;
}

.agendar-select {
  color: #000;
}

.react-dropdown-select,
.react-dropdown-select-content,
.react-dropdown-select-content span {
  color: #000;
  background: #FFF;
}

.pregunta-agendar h4 {
  margin-top: 20px;
  padding: 0;
}

.btn-mandar-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-mandar {
  margin-top: 20px;
  color: #000000;
  width: 100%;
  height: 40px;
}
